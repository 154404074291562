#body{
    margin-left: 80px;
    margin-top: 80px;
    font-size: 16px;
    transition: margin-left 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

section{
    padding: 24px;
}

.menu__side{
    width: 80px;
    height: 100%;
    background: var(--color-blanco);
    position: fixed;
    top: 0;
    left: 0;
    color: var(--color-primario);
    font-size: 16px;
    z-index: 300;
    overflow: hidden;
    overflow-y: scroll;
    border-right: 20px solid var(--color-blanco);
    transition: all 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

/*Ocultar scroll para chrome, safari y opera*/
.menu__side::-webkit-scrollbar{
    display: none;
}

/*Ocultar scroll para IE, Edge y Firefox*/
.menu__side{
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.name__page{
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 15%;
    font-size: 1em;
}

.name__page img{
    /* display: none; */
    width: 120px;
}

.name__page h4{
    text-align: center;
    font-family: var(--fuente-titulo);
    font-size: 1.5625em;
}

.img_menu_move img{
    width: 42px;
}

.options__menu{
    padding: 20px 30px;
    position: absolute;
    cursor: pointer;
}

.options__menu a{
    color: var(--color-primario);
    cursor: default;
    display: block;
    position: relative;
    transition: color 300ms;
    cursor: pointer;
}

.options__menu a:hover{
    color: var(--color-secundario-hover);
}

.options__menu .option{
    padding: 20px 0px;
    display: flex;
    align-items: center;
    position: relative;
}

.options__menu .option:hover{
    color: var(--color-secundario-hover);
}

.options__menu .option i{
    width: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-size: 1.3em;
}

.options__menu .option h4{
    cursor: pointer;
}

a.selected{
    color: var(--color-secundario);
}

.option_enlace.selected{
    color: var(--color-secundario)!important;
    font-weight: bold;
}

.selected:before{
    content: '';
    width: 5px;
    height: 80%;
    background: var(--color-secundario);
    position: absolute;
    top: 10%;
    left: -30px;
}

/*Clases para usar en JavaScript*/

.body_move{
    margin-left: 250px!important;
}

.menu__side_move{
    width: 250px;
}

/* CLASES PARA DARLE MEJOR ESTILO AL MENU COLLAPSE */

#collapseAdministracion .option_enlace, 
#collapseCAS .option_enlace, 
#collapseFinanzas .option_enlace, 
#collapseRecursosHumanos .option_enlace, 
#collapseServicio .option_enlace, 
#collapseCatalogos .option_enlace{
    font-size: 0.85em;
    padding-left: 0.6em;
}

#collapseCatalogos .option_enlace .option{
    padding: 10px 0px;
}



@media screen and (max-width: 760px){
    .name__page h4{
        /* display: none; */
    }

    .body_move{
        margin-left: 0px!important;
    }

    .menu__side_move{
        width: 80px;
        left: -80px;
    }

}

svg {
  /* max-width: 75vw;
  max-height: 80vh;
  border-radius: 100%;
  background-image: linear-gradient(#0c1421, #04060b); */
}